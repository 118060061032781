import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  BlogSlider,
  Breadcrumbs,
  BannerLinks,
  Image,
  ImageSlider,
  CallUs
} from '../../components'

import BannerImage from '../../assets/projektuebersicht.jpg'
import contacts from '../../components/CallUs/DefaultContacts'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'
import websiteApple from '../../assets/websiteapple.png'
import verlaufe from '../../assets/verlaufe.png'
import colorFoto from '../../assets/colorfoto.png'
import gradients from '../../assets/gradients.png'

const images = [
  {
    image: websiteApple,
    alt: "Apple Website"
  },
  {
    image: verlaufe,
    alt: "Verläufe"
  },
  {
    image: colorFoto,
    alt: "Farbverlauf"
  }
]

import { generateBreadcrumbsLinks } from '../../utils/index'

const GradientYourLife = () => {
  const links = generateBreadcrumbsLinks('/blog/gradient-your-life', 'Gradient your life')
  return (
    <Layout>
      <Helmet
        title="Farbverläufe und Effekte"
        meta={[
          {
            name: 'description',
            content:
              'Farbverläufe (gradients) sind schon seit einiger Zeit trendy, sowohl im Hintergrund von Websites, auf Texten, wie auch als Filter auf Bildern. Neu sind Multi-Farbverläufe mit mehr als zwei Farben, sowie darüberliegende irreguläre Überblendungen bzw. Blendeneffekte (blendings).'
          },
          {
            name: 'keywords',
            content:
              'gradient holzweg, Blog holzweg, Innsbruck, holzweg, Blog, gradients, Farbverläufe, Verläufe, Trends 2019, Website Trends 2019, Website Trends'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/verlauf-b95c462b2f194c577196e2cf773da6f9.jpg'
          },
          {
            property: 'og:description',
            content:
              'Farbverläufe (gradients) sind schon seit einiger Zeit trendy, sowohl im Hintergrund von Websites, auf Texten, wie auch als Filter auf Bildern. Neu sind Multi-Farbverläufe mit mehr als zwei Farben, sowie darüberliegende irreguläre Überblendungen bzw. Blendeneffekte (blendings).'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/verlauf-b95c462b2f194c577196e2cf773da6f9.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Es wird bunt!
          </span>
        }
      />
      <Breadcrumbs
        backLinkAlias=' '
        links={links}
      />
      <TextBox
        title="Gradient your life - Farbverläufe und Effekte"
        text="Farbverläufe (gradients) sind schon seit einiger Zeit trendy, sowohl im Hintergrund von Websites, auf Texten, wie auch als Filter auf Bildern. Neu sind Multi-Farbverläufe mit mehr als zwei Farben, sowie darüberliegende irreguläre Überblendungen bzw. Blendeneffekte (blendings)."
      />
      <ImageSlider images={images} />
      <TextBox
        title=""
        text="Wir haben Euch ein paar Beispiele zusammengetragen, welche Arten von Farbverläufen und Überblendungen es gibt:"
      />
      <Image
        image={gradients}
        title="Die gängigsten Verläufe und Überblendungen"
      />
      <TextBox
        title=""
        text={
          <span>
            <h2>Ja zum Verlauf?</h2>
            <p>Wenn Ihr das selbst mal ausprobieren wollt, wie Eure Corporate Design Farben als Verlauf aussehen, könnt Ihr das <a href="https://www.grabient.com/" target="_blank">auf dieser Website</a> ausprobieren .. <br/>.. oder Ihr meldet Euch bei uns ;-)
            </p>
          </span>
        }
      />
      <CallUs contacts={contacts} title="Wir beißen nicht" />
      <BlogSlider cards={cards} singleRow={true} title="Weitere coole Blogeinträge" />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default GradientYourLife
